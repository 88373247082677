/* General body styling */
body {
  /* Light grey background */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

/* Ensures the form and the image container take full height */
.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-bar {
  flex-shrink: 0; /* Ensures the header does not grow */
}

.full-height > .row:nth-child(2) {
  flex-grow: 1; /* Takes the remaining space */
}

.left-container, .right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Make them take up all available space in their row */
}

/* Styles for the form container */
.form-container {
  padding: 20px;
  background-color: #fff; /* White background for the form */
  border-radius: 5px;
}

/* Adjust the margin and padding for form elements */
.form-container .form-group {
  margin-bottom: 15px;
}

/* Style for the upload button */
.upload-btn {
  background-color: #000033; /* Bootstrap primary color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.upload-btn:hover {
  background-color: #0056b3; /* Darken the button on hover */
}

/* Responsive styling for the image container */
.img-container img {
  max-width: 100%;
  height: auto;
  display: block; /* Remove extra space below the image */
  border-radius: 5px; /* Optional: round corners for the image */
}

.right-container {
  background-color: #108359;
}


.footer {
  flex-shrink: 0; /* Ensures the footer stays at the bottom and does not grow */
  text-align: center;
  padding: 10px 0;
  background-color: #ffffff;
}

/* Media queries for responsive adjustments */
@media (max-width: 767.98px) {
  .full-height {
    height: auto; /* Stack form and image vertically on small devices */
  }
  .form-container,
  .img-container {
    margin-bottom: 20px; /* Add some space between the stacked items */
  }
}
