.upload-form {
  margin-top: 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.recaptcha-placeholder {
  margin: 20px 0; /* Spacing around the reCAPTCHA placeholder */
  text-align: center;
  background-color: #f0f0f0; /* Placeholder background */
  padding: 10px;
  border-radius: 5px;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
}

.dropzone.active {
  background-color: #f0f0f0;
}

.file-list {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}

.file-list li {
  background-color: #f2f2f2;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  word-break: break-all;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-file-btn {
  margin-left: 10px; /* Add some space between the filename and the remove button */
}
